<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-12 align-content-center justify-content-center">
          <h1>Транскрибация аудио и видео файлов (до 10 гб), видео youtube vk, yandex-disk и google drive</h1>
        </div>
      </div>
    </div>

    <div class="container">


        <!--begin::Title-->

          <a @click="createProjectLink" class="btn btn-primary">Создать проект</a>
      <br><br>
        <h2> Мои проекты</h2>

        <div class="row" v-for="project in projects">
        <div class="col"><a :href="getProjectURL(project.project_hash)">{{project.project_name}}</a></div>
          <div class="col ml-4">{{project.inserted}}</div>
        </div>

    </div>




    </div>




</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";


let sseClient;


export default {
  components: {
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "WhiperXAddPrject",
  data() {
    return {

      input_api: {
        user_id: 1,
        project_name:'Project',
        threshold:'180',
        enforceTo:'',
        gd_folder:'',
        gd_file:'',
        yd:'',
        yd_folder:'',
        youtube:'',
      },
      tableShow: false,
      showStatus: false,
      status: {
        rows_in: 0, rows_out: 0,
      },
      token: '',
      excelResults: [],
      refreshTime: 1,

      projects: [],


    }
  },
   created() {
     this.hash = this.$CryptoJS.MD5("Hi There!" + new Date()).toString();
   },
  mounted() {
    this.getProjectsList();

    sseClient = this.$sse.create({
      url: '' + this.$hostnamejava + '/whisperx-sse/stream-sse/' + this.hash,
      format: 'plain',
      withCredentials: false,
      polyfill: true,
    });

    sseClient.on('message', this.handleMessage);

    sseClient.connect()
        .then(sse => {
          console.log('We\'re connected!');
        })
        .catch((err) => {
          console.error('Failed to connect to server', err);
        });

  },

  beforeDestroy() {

    sseClient.disconnect();

  },
  methods: {

    handleMessage(message, id) {
      console.log(" message: ");

      console.log(message)
      var ja = JSON.parse(message);
      var map1 = new Map();

      for (let i = 0; i < ja.length; i++) {
        var jo = ja[i];

        var num_job_done = Math.floor(jo.status);
        var userid_str = jo.user_id;


        map1.set(userid_str, num_job_done);
      }

      this.statusProcess = map1.get(''+store.state.user_id);

    },

    createProjectLink() {
      console.log('store user id' + store.state.user_id);
      let hash = this.$CryptoJS.MD5(store.state.user_id + new Date()).toString();
      window.open(this.$hostnamefront+'/whisperx/add-project/'+hash,'_self');
    },

    getProjectURL(hash) {
      console.log('store user id' + store.state.user_id);
      return this.$hostnamefront+'/whisperx/project/'+hash;
    },

    getStatus() {
      this.$vs.loading({
        container: '#div-with-loading-excel',
        scale: 0.5,
        opacity: 0.1,
      });
      this.input_api.user_id = store.state.user_id;
      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/sound2text/get-status',
        // url: '' + this.$hostnamejava + '/sound2text/get-status',
        data: this.input_api,
      }).then((res) => {
        console.log(res.data)
        this.radio2Text = res.data.results;
        this.status.rows_in = res.data.rows_in;
        this.status.rows_out = res.data.rows_out;
        if (this.status.rows_out < this.status.rows_in) {
          this.showStatus = true;
          setTimeout(this.getStatus, 2000); // try again in 2000 milliseconds
        } else {
          this.showStatus = false;
          this.$vs.loading.close("#div-with-loading-excel > .con-vs-loading");
        }

      });

    },


    getProjectsList() {
      console.log('inside get project list')

      this.input_api.user_id = store.state.user_id;
      console.log(this.input_api)
      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisperx/get-projects-list',
        data: this.input_api,
      }).then((res) => {

        this.projects = res.data.results;


      });

    },

  }
}
</script>

<style scoped></style>